import { graphql } from 'gatsby';
import { object } from 'prop-types';
import { StaticPagesTemplate } from '../../../components/common/static-pages-template';

function PostDivorceModifications({ data }) {
  return (
    <StaticPagesTemplate data={data} />
  );
}

export const query = graphql`
  {
    wpPage(uri: {eq: "/castle-rock-divorce-lawyer/post-divorce-modifications/"}) {
      content
      title
      uri
      ...SeoPage
    }
  }
`;

PostDivorceModifications.propTypes = {
  data: object,
};

PostDivorceModifications.defaultProps = {
  data: {},
};

export default PostDivorceModifications;
export { Head } from '../../../components/seo/seo';
